@font-face {
    font-family: 'Hardpixel';
    src: local('Hardpixel'),
    url('./fonts/Hardpixel.otf') format('truetype');
}

@font-face {
    font-family: 'GOST_type_B';
    src: local('GOST_type_B'),
    url('./fonts/GOST_type_B.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
