.backgroundHexWrapper {
    display: grid;
    gap: 2px;
    grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px;
    grid-template-rows: 75px 24px 48px 24px 48px 24px 48px 24px 48px;
}

.animation1:hover {
    opacity: 0;
    transition: opacity 0.2s;
}

.mainBackgroundHex1 {
    grid-area: 1/1/3/3;
}

.mainBackgroundHex2 {
    grid-area: 1/3/3/5;
}

.mainBackgroundHex3 {
    grid-area: 2/2/4/4;
}

.mainBackgroundHex4 {
    grid-area: 4/1/7/3;
}

.mainBackgroundHex5 {
    grid-area: 4/3/7/5;
}

.mainBackgroundHex6 {
    grid-area: 6/2/9/4;
}

.mainBackgroundHex7 {
    grid-area: 2/4/5/6;
}

.mainBackgroundHex8 {
    grid-area: 2/6/5/8;
}

.mainBackgroundHex9 {
    grid-area: 4/5/7/7;
}

.mainBackgroundHex10 {
    grid-area: 1/7/3/9;
}

.mainBackgroundHex11 {
    grid-area: 1/9/3/11;
}

.mainBackgroundHex12 {
    grid-area: 2/8/5/10;
}

.mainBackgroundHex13 {
    grid-area: 4/7/7/9;
}

.mainBackgroundHex14 {
    grid-area: 4/9/7/11;
}

.mainBackgroundHex15 {
    grid-area: 6/8/9/10;
}
