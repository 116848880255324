.AboutWrapper {
    display: grid;
    grid-template-columns: 1fr 238px 7px 70px 8px 157px 8px 70px 8px 157px 9px 70px 9px 93px ;
    grid-template-rows: 8px 133px 8px 133px 8px 133px 8px 133px 8px 133px ;
    height: 1098px;
    background: linear-gradient(360deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);
}

.AboutWrapperBackgroundHex1{
    grid-area: 4/2/7/4;
}

.AboutWrapperBackgroundHex2{
    grid-area: 2/4/5/9;
}

.AboutWrapperBackgroundHex3{
    grid-area: 4/8/6/12;
}

.AboutWrapperBackgroundHex4{
    grid-area: 8/8/11/12;
}

.AboutWrapperBackgroundHex5{
    grid-area: 6/12/9/15;
}

@media (max-width:1919px){
    .AboutWrapperBackgroundHex5{
        display:none;
    }
}