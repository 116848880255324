
.about {
    display: grid;
    justify-content: center;
    /*height: 75px;*/
    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 150px 150px 447px 350px;
    /*width: 1920px;*/
    height: 1097px;
    /*background: linear-gradient(360deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);*/
    color: #ffffff;
    font-family: "Hardpixel";

    position: center;
    z-index: 2;



}

.animation1 {
    z-index: 1;
    grid-area: 3 / 1 / 3 / 8;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}

.animation2 {
    grid-area: 3 / 8 / 3 / 16;
    margin-left: 20px;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation: spin_back 10s linear infinite;
    -moz-animation:spin_back 10s linear infinite;
    animation:spin_back 10s linear infinite;
}

.animation3 {
    grid-area: 3 / 16 / 3 / 24;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}

@-moz-keyframes spin_back {
    100% { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin_back {
    100% { -webkit-transform: rotate(-360deg); }
}
@keyframes spin_back {
    100% {
        -webkit-transform: rotate(-360deg);
        transform:rotate(-360deg);
    }
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

.title {
    grid-area:  1 / 1 / 1 / 7;

    font-size: 75px;
    font-weight: 400;
    /*height: 92px;*/
    /*left: 376px;*/
    letter-spacing: 0;
    line-height: normal;
    align-self: end;
    /*position: relative;*/
    /*text-align: start;*/
    /*top: 100px;*/
}

.description {
    grid-area:  2 / 1 / 2 / 24;

    font-size: 30px;
    font-weight: 400;
    /*height: 92px;*/
    /*left: 376px;*/
    letter-spacing: 0;
    line-height: normal;
    align-self: end;
    text-align: start;
    font-family: "GOST_type_B";

}

.hexagon1 {
    grid-area: 3 / 1 / 3 / 8;
    align-self: center;
    justify-self: center;
    position: relative;
}

.hexagon2 {
    grid-area: 3 / 8 / 3 / 16;
    align-self: center;
    justify-self: center;
}

.hexagon3 {
    grid-area: 3 / 16 / 3 / 24;
    align-self: center;
    justify-self: center;
}


.text1{
    z-index: 2;

    grid-area:  3 / 1 / 3 / 8;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 40px;
    font-family: "GOST_type_B";

}

.text2{
    z-index: 2;

    grid-area:  3 / 8 / 3 / 16;
    margin-left: 20px;

    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: "GOST_type_B";
    font-size: 40px;
    color: #2C1E34;

}

.text3{
    z-index: 2;

    grid-area:  3 / 16 / 3 / 24;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: "GOST_type_B";
    font-size: 40px;

}

.rectangle_3{
    grid-area:  4 / 1 / 4 / 24;
    align-self: center;
    justify-self: center;
    background: linear-gradient(170deg, rgba(44, 30, 52, 0.32) 0%, rgba(44, 30, 52, 1) 100%);
    height: 200px;
    left: 0;
    /*position: absolute;*/
    top: 0;
    width: 1160px;
}

.rectangle_4{
    grid-area:  4 / 1 / 4 / 24;
    align-self: center;
    justify-self: center;
    width: 1160px;
    height: 200px;
    border: 10px solid;
    border-image: linear-gradient(180deg, rgba(0, 30, 49, 1) 0%, rgba(86, 73, 92, 1) 50%, rgba(235, 235, 235, 1) 100%);
    border-image-slice: 1;
    /*left: 0;*/
    /*position: absolute;*/
    /*top: 0;*/
}

.zadacha{
    margin-right: 52px;
    margin-left: 52px;

    grid-area:  4 / 1 / 4 / 24;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: "GOST_type_B";
    font-size: 30px;

}
