@font-face {
    font-family: "Hardpixel";
    font-style: normal;
    /* Браузер сначала попробует найти шрифт локально */
    src: local("Hardpixel"),
        /* Если не получилось, загрузит woff2 */ url("../../fonts/Hardpixel.otf") format("otf");
    /* Если браузер не поддерживает woff2, загрузит woff */
    /*url("/fonts/roboto.woff") format("woff");*/

}

.main_page {
    width: 100%;
    height: 100%;
    background-image: url("../../images/bg_mainPage.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #31213AFF;

    font-family: "Hardpixel", sans-serif;

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 158px 204px 150px 150px 204px 158px;
    /*height: 1024px;*/
}

/*.frame {*/
/*    width: 450px;*/
/*    height: 350px;*/
/*    border: 3px solid #ccc;*/
/*    background: #eee;*/
/*    margin: auto;*/
/*    padding: 15px 25px;*/
/*}*/


.rectangle {
    grid-area: 2/1/6/24;
    align-self: center;
    justify-self: center;
    border: 10px solid;

    border-image: linear-gradient(162deg, rgba(86, 73, 92, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border-image-slice: 1;
    height: 708px;
    width: 1160px;
}

.text1 {
    grid-area: 2/1/3/24;
    justify-self: center;
    align-self: end;
    /*height: 140px;*/
    /*    background-image: linear-gradient(180deg, rgb(86, 73, 92) 0%, rgba(86, 73, 92, 0) 100%);*/
    color: #ffffff;
    -webkit-fill-color: #ffffff;

    font-weight: 400;
    font-size: 140px;
    letter-spacing: 0px;

    -webkit-text-stroke: 8px #56495C; /* Толщина и цвет обводки */
    text-stroke: 8px #56495C; /* Толщина и цвет обводки (стандартное свойство) */


    /*text-shadow: 10px 0 10px #56495C,*/
    /*0 10px 10px #56495C,*/
    /*-10px 0 10px #56495C,*/
    /*0 -10px 10px #56495C;*/

}

.text2 {
    grid-area: 3/1/4/24;

    color: #ffffff;
    font-family: "GOST_type_B";
    font-size: 30px;
    letter-spacing: 0;
    line-height: normal;
    justify-self: center;
    align-self: center;
}

.text_wrapper_30 {
    grid-area: 4/1/5/24;


    color: #ffffff;
    font-family: "Hardpixel", sans-serif;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    justify-self: center;
    align-self: center;
}

.PROGRESS_BAR {

    grid-area: 5/5/6/20;

    background-color: #c1c1c1;
    border: 10px solid;
    border-color: #001e31;
    height: 80px;
    width: 760px;
}

.rectangle_2 {
    grid-area: 5/5/6/20;
    margin: 10px;
    background: linear-gradient(180deg, rgb(3, 166, 166) 0%, rgb(86, 73, 92) 100%);
    height: 80px;
    width: 353px;
}

.text_wrapper_31 {
    grid-area: 5/11/6/14;
    width: 100px;
    /*align-self: center;*/
    justify-self: center;
    text-align: center;
    /* межстрочное расстояние */
    line-height: normal;
    /* ставим содержимое в центр */
    place-items: center;
    /*background: linear-gradient(to left, #ffffff 0%, #000000 50%);*/
    background: linear-gradient(to left, #000000, #000000 50%, #000000 69%, #ffffff 69%); /*transparent 19%,transparent 20%,*/
    /*var(--color-2) 20%, var(--color-2)  39%,*/
    /*transparent 39%,transparent 40%,*/
    /*var(--color-3) 40%,var(--color-3) 59% ,*/
    /*transparent 59%,transparent 60%,*/
    /*var(--color-4) 60%, var(--color-4) 79%,*/
    /*transparent 79%, transparent 80%,*/
    /*var(--color-5) 80%);*/
    /* обрезаем фон по контуру букв */
    background-clip: text;
    -webkit-background-clip: text;

    /* делаем заголовок прозрачным, чтобы был виден цветной фон */
    color: transparent;

    /*-webkit-background-clip: text !important;*/
    -webkit-text-fill-color: transparent;
    /*background: linear-gradient(180deg, rgb(86, 73, 92) 0%, rgb(0, 30, 49) 100%);*/
    /*-webkit-background-clip: text;*/
    /*color: transparent;*/
    font-family: "Hardpixel", sans-serif;
    font-size: 45px;
    letter-spacing: 0;
    margin-top: 18px;
    text-align: center;
}