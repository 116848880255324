.wrapper {
    background-color: #252525;
    /*height: 10877px;*/
    height: auto;
    margin: 0;
}

.layout {
    width: 1200px;
    justify-content: center;
    font-size: x-large;
    /*color: white;*/
    display: grid;
    grid-template-columns: 100%;
    /*height: 100vh;*/
    /*grid-template-rows: repeat(10, 1fr);*/
    grid-template-areas:
                "head"
                "main_page"
                "about"
                "aboutAdv1"
                "aboutAdv2"
                "aboutAdv3"
                "feature"
                "roadmap"
                "follow_us"
                "foot";
}

.head {
    grid-area: head;
    /*background-color: #f14141;*/
}

.main_pageWrapper {
    grid-area: main_page;
    z-index: 1;
}

.main_page {
    grid-area: main_page;
    z-index: 2;
}

.aboutWrapper {
    grid-area: about;
    z-index: 1
}

.about {
    grid-area: about;
    z-index: 2
}

.aboutAdv1Wrapper {
    grid-area: aboutAdv1;
    z-index: 3
}

/*.aboutAdv1_and_Wrapper {*/
/*    grid-area: aboutAdv1;*/
/*    !*background-color: #4b68ff;*!*/
/*    z-index: 4*/
/*}*/

.aboutAdv1 {
    grid-area: aboutAdv1;
    /*background-color: #4b68ff;*/
    z-index: 4
}

.aboutAdv2 {
    grid-area: aboutAdv2;
    /*background-color: #787883;*/
    z-index: 4

}

.aboutAdv3 {
    grid-area: aboutAdv3;
    /*background-color: #002bf3;*/
    z-index: 4

}

.featureWrapper {
    grid-area: feature;
    z-index: 1
}

.feature {
    grid-area: feature;
    /*background-color: #bd0096;*/
    z-index: 2
}

.roadmapWrapper1 {
    grid-area: roadmap;
    z-index: 1
}

.roadmapWrapper2 {
    grid-area: roadmap;
    z-index: 2
}

.roadmap {
    grid-area: roadmap;
    /*background-color: #2a980a;*/
    z-index: 3

}

.follow_usWrapper {
    grid-area: follow_us;
    z-index: 1
}

.follow_us {
    grid-area: follow_us;
    z-index: 2
}

.foot {
    grid-area: foot;
}

@media only screen and (min-width: 768px) {
    .layout {
        font-size: x-large;
        /*color: white;*/
        display: grid;
        width: 100%;
        /*height: 100vh;*/
        grid-template-columns: 1fr repeat(11, 60px 40px) 60px 1fr;
        /*grid-template-rows: 75px 1024px 1097px 833px 2248px 1608px 1867px 1150px 900px 75px;*/
        grid-template-areas:
                "head head head head head head head head head head head head head head head head head head head head head head head head head "
                "main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page main_page "
                "about about about about about about about about about about about about about about about about about about about about about about about about about"
                "aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1 aboutAdv1"
                "aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2 aboutAdv2"
                "aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3 aboutAdv3"
                "feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature feature "
                "roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap roadmap "
                "follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us follow_us "
                "foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot foot ";
    }
}
