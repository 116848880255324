.RoadmapWrapper1 {
    position: relative;

    display: grid;
    grid-template-columns: 94px 8px 137px 8px 182px 1fr;
    grid-template-rows: 100px 76px 8px 76px 8px 76px 8px 76px 8px 76px 8px 126px 8px 126px 8px 126px 1fr;
    height: 1150px;
    background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);
}

.animation1 {
    position: absolute;
}

.animation1:hover {
    z-index: 100;
    opacity: 0;
    transition: opacity 0.2s;
}

.RoadmapWrapperBackgroundHex1 {
    position: relative;
    grid-area: 2/1/9/4;
}

.RoadmapWrapperBackgroundHex2 {
    grid-area: 4/3/6/6;
}

.RoadmapWrapperBackgroundHex3 {
    grid-area: 8/3/11/6;
}

.RoadmapWrapperBackgroundHex4 {
    grid-area: 10/5/11/6;
}

.RoadmapWrapperBackgroundHex5 {
    grid-area: 2/5/5/8;
}

.RoadmapWrapperBackgroundHex6 {
    grid-area: 4/9/7/12;
}

.RoadmapWrapperBackgroundHex7 {
    grid-area: 4/9/7/12;
}

.RoadmapWrapperBackgroundHex8 {
    grid-area: 4/9/7/12;
}

.RoadmapWrapperBackgroundHex9 {
    grid-area: 4/9/7/12;
}

@media (max-width:1919px){
    .RoadmapWrapperBackgroundHex1{
        display:none;
    }
    .RoadmapWrapperBackgroundHex2{
        display:none;
    }
    .RoadmapWrapperBackgroundHex3{
        display:none;
    }
    .RoadmapWrapperBackgroundHex4{
        display:none;
    }

}