.head {
    display: grid;
    justify-content: center;
    height: 75px;
    grid-template-columns: repeat(11, 60px 40px) 60px;
    background-color: #252525;

    /*grid-template-areas:*/
    /*"h_l h_l h_l h_l n n n n n n n n n n n n n n n n h_s h_s h_s ";*/
}


/*.header img {*/
/*    !*margin: 8px;*!*/
/*    vertical-align: middle;*/
/*    width: 100%;*/
/*}*/

.header_logo {
    grid-column: 1/5;
    /*grid-area: h_l;*/
    /*background-color: #ffdbdb;*/
    justify-self: start;

}

.navLink{
    border-radius: 3px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    -webkit-transition: background 0.6s ease;
    transition: background 0.6s ease;
    vertical-align: middle;
    background: linear-gradient(135deg, #03A6A6 10%, #9AEB9A 100%);

    color: transparent;
    -webkit-background-clip: text;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;



}

.navbar {
    grid-column: 5/21;
    justify-content: space-around;
    text-align: justify;
    align-self: center;
    align-content: center;


}

.header_socNet {
    grid-column: 21/24;
    /*grid-area: h_s;*/
    /*background-color: #a10000;*/
    align-self: center;
    justify-self: center;
    /*justify-self: end;*/
    /*gap: 15px;*/
}

.header_socNet img {
    text-align: right;
padding-left: 15px;
}
