.foot {
    display: grid;
    height: 75px;
    grid-template-columns:  1fr repeat(11, 60px 40px) 60px 1fr;

    background-color: #252525;
}

.data{
    grid-area: 1 / 2 / 2 / 4;
    font-size: 16px;
    color: rgba(193, 193, 193, 0.5);
    align-self: center;
    justify-self: start;
}

.text1{
    grid-area: 1 / 4 / 2 / 13;
    margin-right: 5px;

    border-radius: 3px;
    display: inline-block;
    -webkit-transition: background 0.6s ease;
    transition: background 0.6s ease;
    vertical-align: middle;
    -webkit-background-clip: text;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    color: rgba(193, 193, 193, 0.5);
    align-self: center;
    justify-self: end;
    text-decoration: none;
    z-index: 3;

}

.text1 a{
    text-decoration: none;
    color: inherit;
    z-index: 3;

}

.logo{
    grid-area: 1 / 13 / 2 / 14;
    align-self: center;
    justify-self: center;
    margin-top: 5px;
    z-index: 3;

}

.text2{
    grid-area: 1 / 14 / 2 / 24;
    margin-left: 5px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: background 0.6s ease;
    transition: background 0.6s ease;
    vertical-align: middle;
    -webkit-background-clip: text;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    color: rgba(193, 193, 193, 0.5);
    align-self: center;
    justify-self: start;
    text-decoration: none;
    z-index: 3;

}

.text2 a{
    text-decoration: none;
    color: inherit;
    z-index: 3;


}