.AboutAdv1Wrapper {
    display: grid;
    grid-template-columns: 76px 10px 74px  1fr ;
    grid-template-rows: 1fr 4px 130px 130px 130px 8px 130px 130px 8px 130px;
    background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);

}
/*.aboutAdv1Wrapper {*/
/*    grid-area: aboutAdv1;*/
/*   z-index: 3*/
/*}*/

.AboutAdv1WrapperBackgroundHex1{
    grid-area: 2/1/4/4;

}

.AboutAdv1WrapperBackgroundHex2{
    grid-area: 8/1/10/4;
    margin-top: 4px;

}

.AboutAdv1WrapperBackgroundHex3{
    grid-area: 4/3/6/5;

}

.AboutAdv1WrapperBackgroundHex4{
    grid-area: 7/3/9/5;

}

.AboutAdv1WrapperBackgroundHex5{
    grid-area: 10/3/11/5;
    /*z-index: 3*/

}