.aboutAdv3 {
    display: grid;
    justify-content: center;

    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 200px 150px 400px 375px 275px 150px;
    /*width: 1920px;*/
    height: 1608px;
    /*background: linear-gradient(180deg,transparent 0%, rgb(3, 166, 166) 40%, rgba(49, 33, 58, 1) 85%);*/
    color: white;
    font-size: 30px;
    background: linear-gradient(180deg,transparent 0, transparent 20%, rgb(3, 166, 166) 40%, rgba(49, 33, 58, 1) 85%);

    font-family: Hardpixel;
}

.rectangle1 {
    grid-area: 1/1/2/12;
    align-self: end;
    justify-self: start;

    width: 540px;
    height: 80px;
    color: transparent;
    border-color: white;
    border-style: solid;
    border-width: 10px;
}

.button:hover{
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(-1px);
}

.button:active {
    /*background-color: #F0F0F1;*/
    border-color: rgba(0, 0, 0, 0.65);
    box-shadow: rgba(0, 0, 0, 0.65) 0 2px 4px;
    color: rgba(0, 0, 0, 1);
    transform: translateY(0);
}

.button {
    width: 540px;
    height: 100px;
    border-width: 10px;
    cursor: pointer;
    display: inline-block;
    font-family: "Hardpixel";
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    color: white;
    border-color: white;
    border-style: solid;
    font-size: 45px;
}

.button1{
    grid-area: 1/1/2/12;
    align-self: end;
    justify-self: center;
    margin-bottom: 20px;
    font-size: 45px;
}


.title {
    grid-area: 2/3/3/24;
    align-self: center;
    justify-self: start;

    font-size: 75px;
}

.text2 {
    grid-area: 3/3/4/24;
    align-self: start;
    justify-self: start;
    font-family: "GOST_type_B";

}

.text2 span {
    color:   #93EFEF;
}

.hex1 {
    grid-area: 4/1/5/6;
    align-self: center;
    justify-self: center;
}

.hex2 {
    grid-area: 4/7/5/12;
    align-self: center;
    justify-self: center;
}

.hex3 {
    grid-area: 4/13/5/18;
    align-self: center;
    justify-self: center;
}

.hex4 {
    grid-area: 4/19/5/24;
    align-self: center;
    justify-self: center;
}

.textHex1 {
    grid-area: 4/1/5/6;
    align-self: center;
    justify-self: start;
    font-family: GOST_type_B;
    text-align: center;

}

.textHex2 {
    grid-area: 4/7/5/12;
    align-self: center;
    justify-self: start;
    font-family: GOST_type_B;
    text-align: center;

}

.textHex3 {
    grid-area: 4/13/5/18;
    align-self: center;
    justify-self: start;
    font-family: GOST_type_B;
    text-align: center;

}

.textHex4 {
    grid-area: 4/19/5/24;
    align-self: center;
    justify-self: start;
    font-family: GOST_type_B;
    text-align: center;

}

.rectangle2 {
    grid-area: 5/1/7/24;
    align-self: end;
    justify-self: start;

    width: 1160px;
    height: 275px;
    color: transparent;
    border-color: white;
    border-style: solid;
    border-width: 10px;
    background: linear-gradient(30deg, #31223B 0%, #31223B 50%, #56495C 100%);

}

.text5 {
    grid-area: 5/1/6/24;
    align-self: end;
    justify-self: center;

    text-align: center;
    font-family: GOST_type_B;
}

.text6 {
    grid-area: 6/1/7/24;
    align-self: center;
    justify-self: center;

    font-size: 60px;
}