.aboutAdv2 {
    display: grid;
    justify-content: center;
    /*height: 75px;*/
    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 250px 200px 250px 98px 100px 100px 100px 150px 250px 150px 400px 100px;
    /*width: 1920px;*/
    height: 2248px;
    background-clip: border-box;
    /*background: linear-gradient(180deg, rgb(3, 166, 166) 5%, rgba(49, 33, 58, 1) 50%);*/
    background: linear-gradient(180deg,transparent 0, transparent 20%, rgb(3, 166, 166) 40%, rgba(49, 33, 58, 1) 85%);
    color: white;
    font-family: Hardpixel;
/*z-index: 4;*/
}

.animation1 {
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin_back {
    100% { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin_back {
    100% { -webkit-transform: rotate(-360deg); }
}
@keyframes spin_back {
    100% {
        -webkit-transform: rotate(-360deg);
        transform:rotate(-360deg);
    }
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

/*.rectangle1 {*/
/*    grid-area: 1/1/2/12;*/
/*    align-self: end;*/
/*    justify-self: start;*/

/*    width: 540px;*/
/*    height: 80px;*/
/*    color: transparent;*/
/*    border-color: white;*/
/*    border-style: solid;*/
/*    border-width: 10px;*/
/*}*/

/*.text1 {*/
/*    grid-area: 1/1/2/12;*/
/*    align-self: end;*/
/*    justify-self: center;*/

/*    font-size: 45px;*/
/*    margin-bottom: 20px;*/
/*}*/
.button:hover{
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(-1px);
}

.button:active {
    /*background-color: #F0F0F1;*/
    border-color: rgba(0, 0, 0, 0.65);
    box-shadow: rgba(0, 0, 0, 0.65) 0 2px 4px;
    color: rgba(0, 0, 0, 1);
    transform: translateY(0);
}
.button {
    width: 540px;
    height: 100px;
    border-width: 10px;
    cursor: pointer;
    display: inline-block;
    font-family: "Hardpixel";
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    color: white;
    border-color: white;
    border-style: solid;
    font-size: 45px;

}
.button1{
    grid-area: 1/1/2/12;

    align-self: center;
    justify-self: start;
}
.title {
    grid-area: 2/3/3/24;
    align-self: center;
    justify-self: start;

    font-size: 75px;
}

.text2 {
    grid-area: 3/3/4/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    font-family: "GOST_type_B";

}

.text3 {
    grid-area: 4/3/5/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    font-family: "GOST_type_B";

}

.img1 {
    grid-area: 5/3/6/4;
    align-self: center;
    justify-self: center;
}

.text4 {
    grid-area: 5/5/6/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    margin-bottom: 10px;
    font-family: "GOST_type_B";


}

.img2 {
    grid-area: 6/3/7/4;
    align-self: center;
    justify-self: center;
}

.text5 {
    grid-area: 6/5/7/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    margin-bottom: 10px;
    font-family: "GOST_type_B";


}

.img3 {
    grid-area: 7/3/8/4;
    align-self: center;
    justify-self: center;
}

.text6 {
    grid-area: 7/5/8/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    margin-bottom: 10px;
    font-family: "GOST_type_B";


}

.rectangle2 {
    grid-area: 8/1/10/24;
    /*align-self: center;*/
    /*justify-self: start;*/

    width: 1160px;
    /*height: 360px;*/
    height: 400px;
    color: transparent;
    background: linear-gradient(30deg, transparent 0%, #31223B 50%, #56495C 100%);
    border-color: white;
    border-style: solid;
    border-width: 10px;
}

.text7 {
    grid-area: 8/1/9/24;
    align-self: center;
    justify-self: center;

    font-size: 45px;
    color: #93EFEF;
}

.text8 {
    grid-area: 9/3/10/22;
    align-self: center;
    justify-self: center;

    text-align: center;
    font-size: 30px;
    font-family: "GOST_type_B";

}

.text9 {
    grid-area: 10/1/11/24;
    align-self: end;
    justify-self: start;

    font-size: 75px;
}

.text10 {
    grid-area: 11/1/12/24;
    align-self: center;
    justify-self: center;

    font-size: 30px;
    font-family: "GOST_type_B";

}

/*.rectangle3 {*/
/*    grid-area: 12/13/13/24;*/
/*    align-self: center;*/
/*    justify-self: start;*/

/*    width: 540px;*/
/*    height: 80px;*/
/*    color: transparent;*/
/*    border-color: white;*/
/*    border-style: solid;*/
/*    border-width: 10px;*/
/*}*/

/*.text11 {*/
/*    grid-area: 12/13/13/24;*/
/*    align-self: center;*/
/*    justify-self: center;*/

/*    text-align: center;*/
/*    font-size: 45px;*/
/*}*/

.text12 {
    color: #93EFEF;
}

.button2{
    grid-area: 12/13/13/24;
    align-self: center;
    justify-self: start;
}
