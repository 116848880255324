@media only screen and (min-width: 1600px) {
    .Follow_usWrapper {

        display: grid;

        /*grid-template-columns: 1fr 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px 88px 1px;*/
        /*grid-template-rows: 100px 76px 8px 76px 8px 76px 8px 76px 8px 76px 8px 126px 8px 126px 8px 126px 1fr;*/
        grid-template-columns: 9fr 4fr 1fr 4fr 1fr;
        grid-template-rows: 100px 269px 431px 100px;
        width: 100%;
        height: 900px;
        background-image: url("../../images/FollowUsBGImg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-color: #31213AFF;
        background-size: cover;
    }

    .group1 {
        grid-area: 3/2/4/4;
    }

    /*.animation1:hover {*/
    /*    opacity: 0;*/
    /*    transition: opacity 0.2s;*/
    /*}*/
    .group2 {
        grid-area: 2/3/3/5;
    }

    .RoadmapWrapperBackgroundHex2 {
        grid-area: 4/3/6/6;
    }

    .RoadmapWrapperBackgroundHex3 {
        grid-area: 8/3/11/6;
    }

    .RoadmapWrapperBackgroundHex4 {
        grid-area: 10/5/11/6;
    }

    .RoadmapWrapperBackgroundHex5 {
        grid-area: 2/5/5/8;
    }

    .RoadmapWrapperBackgroundHex6 {
        grid-area: 4/9/7/12;
    }

    .RoadmapWrapperBackgroundHex7 {
        grid-area: 4/9/7/12;
    }

    .RoadmapWrapperBackgroundHex8 {
        grid-area: 4/9/7/12;
    }

    .RoadmapWrapperBackgroundHex9 {
        grid-area: 4/9/7/12;
    }
}

@media (max-width:1599px){
    .group1{
        display:none;
    }

    .group2{
        display:none;
    }
}



/*@media (max-width:[разрешение экрана]px)*/
/*{ [селектор блока]{display:none;} }*/


/*[разрешение экрана]*/
/*если экран меньше или равен этой ширине, то элемент не будет отображаться*/

/*[селектор блока]*/
/*для <div id=qwe></div> будет #qwe*/
/*                  для <div class=qwe></div> будет .qwe*/
/*                                       для <img src="..." /> будет img*/

@media only screen and (max-width: 1599px) {
    .Follow_usWrapper {


        width: 100%;
        height: 900px;
        background-image: url("../../images/FollowUsBGImg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-color: #31213AFF;
        background-size: cover;
    }
}