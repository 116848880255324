.roadmap {
    display: grid;
margin-top: 15px;
    grid-template-columns: repeat(11, 60px 40px) 60px;
    /*grid-template-rows: 150px 200px 200px 200px 200px 200px;*/
    /*grid-template-rows: 300px 200px 200px 200px 200px 200px;*/
    grid-template-rows: 250px 159px 26px 135px 26px 135px 26px 135px 26px 135px 26px;

    /*width: 1920px;*/
    /*width: 1476px;*/

    /*height: 1150px;*/
    /*background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);*/
    font-family: GOST_type_B;
    font-size: 30px;
    justify-content: center;
    top: 0;
    position: center;

}

.roadmap li {
    list-style-type: square;
}

.title {
    grid-area: 1/1/2/24;
    justify-self: start;
    align-self: end;
    font-family: Hardpixel;
    margin-bottom: 50px;
    color: white;
    font-size: 75px;
}

.head1 {
    grid-area: 2/1/4/4;
    justify-self: center;
    align-self: center;

    font-size: 40px;
    text-align: center;
    margin-bottom: 5px;
}

.text1 {
    grid-area: 2/7/4/24;
    justify-self: start;
    align-self: center;
    color: white;
}

.head2 {
    grid-area: 3/2/6/6;
    justify-self: center;
    align-self: center;

    font-size: 40px;
    text-align: center;
    margin-left: 10px;
    margin-bottom: 5px;

}

.text2 {
    grid-area: 3/8/6/24;
    justify-self: start;
    align-self: center;
    margin-left: 16px;
    color: white;
}

.head3 {
    grid-area: 5/1/8/4;
    justify-self: center;
    align-self: center;

    font-size: 40px;
    text-align: center;
    margin-bottom: 5px;

}

.text3 {
    grid-area: 5/7/8/24;
    justify-self: start;
    align-self: center;
    color: white;
}

.head4 {
    grid-area: 7/2/10/6;
    justify-self: center;
    align-self: center;

    font-size: 40px;
    text-align: center;
    margin-left: 10px;
    margin-bottom: 5px;

}

.text4 {
    grid-area: 7/8/10/24;
    justify-self: start;
    align-self: center;
    margin-left: 16px;

    color: white;
}

.head5 {
    grid-area: 9/1/12/4;
    justify-self: center;
    align-self: center;

    font-size: 40px;
    text-align: center;
    margin-bottom: 5px;

}

.text5 {
    grid-area: 9/7/12/24;
    justify-self: start;
    align-self: center;
    color: white;
}

.hex1 {

    grid-area: 2/1/4/4;
    justify-self: start;
    align-self: center;
}

.hex2 {
    grid-area: 3/2/6/6;
    justify-self: center;
    align-self: center;
    margin-left: 10px;

}

.hex3 {
    grid-area: 5/1/8/4;
    justify-self: center;
    align-self: center;
}

.hex4 {
    grid-area: 7/2/10/6;
    justify-self: center;
    align-self: center;
    margin-left: 10px;
}

.hex5 {
    grid-area: 9/1/12/4;
    justify-self: center;
    align-self: center;
}