.follow_us {
    display: grid;
    justify-content: center;
    height: 900px;
    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 200px 100px 55px 100px 150px 295px;
    /*width: 1920px;*/

}

.title {
    grid-area: 1 / 1 / 2 / 14;

    color: #ffffff;
    font-family: "Hardpixel", Helvetica;
    font-size: 75px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    align-self: end;
    margin-bottom: 25px;
}

.text1 {
    grid-area: 2 / 1 / 3 / 14;

    color: #ffffff;
    font-family: "GOST_type_B", Helvetica;
    font-size: 40px;
    font-weight: 400;
    /*height: 92px;*/
    /*left: 376px;*/
    letter-spacing: 0;
    line-height: normal;
    align-self: center;
    /*position: relative;*/
    /*text-align: start;*/
    /*top: 100px;*/
}

/*.text2 {*/
/*    grid-area: 3 / 1 / 4 / 12;*/
/*    padding-left: 18px;*/
/*    padding-top: 10px;*/
/*    color: #ffffff;*/
/*    font-family: "Hardpixel", Helvetica;*/
/*    font-size: 30px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0;*/
/*    line-height: normal;*/
/*    align-self: center;*/
/*    justify-self: center;*/

/*    !*position: relative;*!*/
/*    !*text-align: start;*!*/
/*    !*top: 100px;*!*/
/*}*/

/*.rectangle2 {*/
/*    grid-area: 3 / 1 / 4 / 12;*/
/*    align-self: start;*/
/*    justify-self: start;*/
/*    width: 560px;*/
/*    height: 55px;*/
/*    border: 3px solid;*/
/*    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(3, 166, 166, 1) 100%);*/
/*    border-image-slice: 1;*/
/*    !*left: 0;*!*/
/*    !*position: absolute;*!*/
/*    !*top: 0;*!*/
/*}*/

.text3 {
    grid-area: 3 / 13 / 4 / 18;
    padding-left: 18px;
    padding-top: 10px;
    color: #020101;
    font-family: "Hardpixel", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    align-self: center;
    justify-self: center;
}

.button1 {
    grid-area: 3 / 13 / 4 / 18;
    align-self: center;
    justify-self: center;
    background: linear-gradient(180deg, rgba(3, 166, 166, 1) 0%, rgba(255, 255, 255, 1) 100%);
    width: 260px;
    height: 55px;
    color: #020101;
    font-family: "Hardpixel", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    border: none;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}


.button1:hover {
    border-color: rgba(0, 0, 0, 0.35);
    box-shadow: rgb(0, 0, 0) 0 4px 12px;
    color: rgb(0, 0, 0);
    transform: translateY(-1px);
}

.button1:active {
    /*background-color: #F0F0F1;*/
    border-color: rgba(0, 0, 0, 0.65);
    box-shadow: rgba(255, 255, 255, 0.65) 0 2px 4px;
    color: rgb(255, 255, 255);
    transform: translateY(0);
}


.text4 {
    grid-area: 4 / 1 / 5 / 12;

    color: #ffffff;
    font-family: "GOST_type_B";
    font-size: 40px;
    font-weight: 400;
    /*height: 92px;*/
    /*left: 376px;*/
    letter-spacing: 0;
    line-height: normal;
    align-self: end;
    /*position: relative;*/
    /*text-align: start;*/
    /*top: 100px;*/
}

.example11 {
    grid-area: 3 / 13 / 4 / 18;

    position: relative;
    /*width: 560px;*/
    height: 55px;
    /*margin: 30px;*/
    border-radius: 30px;
    background: transparent;
    text-align: center;

}

.example1 {
    grid-area: 3 / 1 / 4 / 12;

    position: relative;
    width: 560px;
    height: 55px;
    /*margin: 30px;*/
    border-radius: 30px;
    background: transparent;
    text-align: center;

}

/*.example1::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: -2px;*/
/*    bottom: -2px;*/
/*    left: -2px;*/
/*    right: -2px;*/
/*    border: 3px solid;*/
/*    z-index: -1;*/
/*    align-self: start;*/
/*    justify-self: start;*/
/*    width: 560px;*/
/*    height: 55px;*/
/*    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(3, 166, 166, 1) 100%);*/
/*    border-image-slice: 1;*/
/*    !*left: 0;*!*/
/*    !*position: absolute;*!*/
/*    !*top: 0;*!*/

/*}*/

.example1 {

    outline: none;
    background-color: transparent;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    color: white;


    border: 3px solid;
    z-index: 9;
    align-self: start;
    justify-self: start;
    width: 560px;
    height: 55px;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(3, 166, 166, 1) 100%);
    border-image-slice: 1;
    font-family: "GOST_type_B";
    font-weight: 400;
}

/*textarea:focus{*/
/*    outline: none;*/
/*}*/

/*.example1::placeholder {*/
/*    color: #696969;*/
/*}*/
/*.example1{*/
/*    color: #ffffff;*/
/*    font-family: "Hardpixel", Helvetica;*/
/*    font-size: 30px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0;*/
/*    line-height: normal;*/
/*    align-self: center;*/
/*    justify-self: center;*/
/*    grid-area: 3 / 1 / 4 / 12;*/
/*    padding-left: 25px;*/
/*       padding-top: 18px;*/
/*    width: 560px;*/
/*    height: 55px;*/
/*    border: none;*/
/*    background: transparent;*/
/*    !*border-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(3, 166, 166, 1) 100%);*!*/
/*    !*border-image-slice: 1;*!*/
/*    resize: none;*/
/*    !*left: 0;*!*/
/*    !*position: absolute;*!*/
/*    !*top: 0;*!*/
/*}*/

.socNet1 {
    grid-area: 5 / 1 / 6 / 2;
    align-self: center;

}

.socNet2 {
    grid-area: 5 / 3 / 6 / 4;
    align-self: center;

}

.socNet3 {
    grid-area: 5 / 5 / 6 / 6;
    align-self: center;

}

.socNet4 {
    grid-area: 5 / 7 / 6 / 8;
    align-self: center;

}
