.aboutAdv1 {
    display: grid;
    justify-content: center;

    grid-template-columns: repeat(11, 60px 40px) 60px;
    grid-template-rows: 150px 105px 150px 105px 70px 70px 100px;
    /*width: 1920px;*/
    /*height: 1105px;*/
    /*background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);*/
    color: white;
    font-family: Hardpixel;
    top: 0;
    position: center;
    z-index: 2;
    /*background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);*/
    /*height: 861px;*/

}

.title {
    grid-area: 1/3/2/24;
    align-self: center;
    justify-self: start;

    font-size: 75px;
}

.text1 {
    grid-area: 2/3/3/24;
    align-self: start;
    justify-self: start;

    font-size: 30px;
    font-family: "GOST_type_B";

}

.text2 {
    grid-area: 3/3/4/24;
    font-family: GOST_type_B;
    align-self: end;
    justify-self: start;

    font-size: 30px;
}

.text3 {
    grid-area: 4/3/5/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
}

.img1 {
    grid-area: 5/3/6/3;
    align-self: center;
    justify-self: center;
}

.text4 {
    grid-area: 5/5/6/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    margin-bottom: 10px;
    font-family: "GOST_type_B";

}

.img2 {
    grid-area: 6/3/7/3;
    align-self: center;
    justify-self: center;
}

.text5 {
    grid-area: 6/5/7/24;
    align-self: center;
    justify-self: start;

    font-size: 30px;
    margin-bottom: 10px;
    font-family: "GOST_type_B";


}
.animation1 {
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin_back {
    100% { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin_back {
    100% { -webkit-transform: rotate(-360deg); }
}
@keyframes spin_back {
    100% {
        -webkit-transform: rotate(-360deg);
        transform:rotate(-360deg);
    }
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

/*.rectangle {*/
/*    grid-area: 7/13/8/24;*/
/*    align-self: center;*/
/*    justify-self: start;*/

/*    width: 540px;*/
/*    height: 80px;*/
/*    color: transparent;*/
/*    border-color: white;*/
/*    border-style: solid;*/
/*    border-width: 10px;*/
/*}*/

.button:hover{
    border-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: rgba(255, 255, 255);
    transform: translateY(-1px);
}

.button:active {
    /*background-color: #F0F0F1;*/
    border-color: rgba(255, 255, 255, 0.65);
    box-shadow: rgba(0, 0, 0, 0.65) 0 4px 6px;
    color: rgb(255, 255, 255);
    transform: translateY(0);
}
.button {
    width: 540px;
    height: 100px;
    border-width: 10px;
    cursor: pointer;
    display: inline-block;
    font-family: "Hardpixel";
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    color: #ffffff;
    border-color: #ffffff;
    border-style: solid;
    font-size: 45px;
}
.navLink{
    text-decoration: none;
}
a {
    text-decoration: none;
}
.button1 {
    grid-area: 7/13/8/24;
    align-self: center;
    justify-self: start;
    text-decoration: none;
}

/*.text6 {*/
/*    grid-area: 7/13/8/24;*/
/*    align-self: center;*/
/*    justify-self: center;*/

/*    font-size: 45px;*/
/*}*/



