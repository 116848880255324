.featureWrapper {
    display: grid;
    grid-template-columns: 639px 198px 639px;
    grid-template-rows: 368px 368px 368px 368px 368px;
    height: 1767px;
    /*background: linear-gradient(0deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 85%);*/
    /*width: 1476px;*/
    font-family: GOST_type_B;
    margin-top: 100px;
    justify-content: center;
    text-align: center;
    top: 0;
    position: relative
}

.feature {
    display: grid;

    grid-template-columns: repeat(11, 60px 40px) 60px;
    /*grid-template-rows: 330px 330px 330px 330px 547px;*/
    /*width: 1160px;*/
    grid-template-rows: 368px 368px 368px 368px 368px 27px;

    width: 1476px;
    height: 1867px;
    /*background: linear-gradient(0deg, rgb(3, 166, 166) 0%, rgba(49, 33, 58, 1) 85%);*/
    color: white;
    font-size: 30px;
    justify-content: center;
    top: 0;
    position: center;
}

.title {
    grid-area: 1/1/2/12;
    font-size: 75px;
    font-family: Hardpixel;
    align-self: center;
    margin-bottom: 50px;
    margin-left: 100px;

}

.head1 {
    grid-area: 1/15/2/22;
    font-size: 60px;
    align-self: start;
    margin-top: 110px;
    margin-left: 40px;

}

.text1 {
    grid-area: 1/14/3/24;
    align-self: center;
    margin-top: 100px;

}

.head2 {
    grid-area: 2/2/3/10;
    font-size: 60px;
    align-self: start;
    margin-top: 180px;
    /*margin-left: 20px;*/
}

.text2 {
    grid-area: 2/1/4/11;
    align-self: center;
    margin-top: 140px;
    margin-left: 20px;

}

.head3 {
    grid-area: 3/15/4/22;
    font-size: 60px;
    align-self: start;
    margin-top: 110px;
    text-align: center;
}

.text3 {
    grid-area: 3/14/5/24;
    align-self: center;
    margin-top: 110px;
}

.animation1 {
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
.head4 {
    grid-area: 4/2/5/10;
    font-size: 60px;
    align-self: start;
    margin-top: 150px;
    /*margin-left: 20px;*/
}

.text4 {
    grid-area: 4/1/6/11;
    align-self: center;
    margin-top: 150px;

}

.hex1 {
    grid-area: 1/2/3/4;
    align-self: center;
    justify-self: center;
}


.hex2 {
    grid-area: 2/1/4/3;
    align-self: center;
    justify-self: center;
}

.hex3 {
    grid-area: 3/2/5/4;
    align-self: center;
    justify-self: center;
}

.hex4 {
    grid-area: 4/1/6/3;
    align-self: center;
    justify-self: center;
}

@media (max-width:1599px){
    .hex1{
        display:none;
    }

    .hex2{
        display:none;
    }
    .hex3{
        display:none;
    }
    .hex4{
        display:none;
    }
}