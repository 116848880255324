.FeatureWrapper {
    display: grid;
    grid-template-columns: 78px 8px 226px 7px 68px 8px 149px 8px 76px 8px 218px 1fr ;
    grid-template-rows: 4px 126px 8px 126px 8px 126px 8px 126px 8px 126px 8px 126px 1fr;
    height: 1867px;
    background: linear-gradient(0deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 85%);
}

.FeatureWrapperBackgroundHex1{
    grid-area: 6/1/9/4;
}

.FeatureWrapperBackgroundHex2{
    grid-area: 10/1/13/4;
}

.FeatureWrapperBackgroundHex3{
    grid-area: 4/3/6/6;
}

.FeatureWrapperBackgroundHex4{
    grid-area: 8/3/11/6;
}

.FeatureWrapperBackgroundHex5{
    grid-area: 2/5/5/8;
}
.FeatureWrapperBackgroundHex6{
    grid-area: 4/9/7/12;
}

.animation1:hover {
    opacity: 0;
    transition: opacity 0.2s;
}