.RoadmapWrapper2 {
    display: grid;
    grid-template-columns: 1fr 105px 8px 105px 8px 105px 8px 105px 8px 105px 8px;
    grid-template-rows: 300px 189px 8px 55px 8px 126px 8px 55px 8px 126px 8px 55px 8px 189px 1fr;
    /*height: 1867px;*/
    /*background: linear-gradient(180deg, rgb(3, 166, 166) 15%, rgba(49, 33, 58, 1) 100%);*/
}

/*.animation1:hover {*/
/*    opacity: 0;*/
/*    transition: opacity 0.2s;*/
/*}*/
.RoadmapWrapperBackgroundHex5 {
    grid-area: 12/2/15/5;
}

.RoadmapWrapperBackgroundHex6 {
    grid-area: 2/4/5/7;
}

.RoadmapWrapperBackgroundHex7 {
    grid-area: 8/4/11/7;
}

.RoadmapWrapperBackgroundHex8 {
    grid-area: 4/6/7/9;
}

.RoadmapWrapperBackgroundHex9 {
    grid-area: 8/8/13/11;
}

@media (max-width:1599px){
    .RoadmapWrapperBackgroundHex5{
        display:none;
    }

    .RoadmapWrapperBackgroundHex6{
        display:none;
    }

    .RoadmapWrapperBackgroundHex7{
        display:none;
    }
    .RoadmapWrapperBackgroundHex8{
        display:none;
    }
    .RoadmapWrapperBackgroundHex9{
        display:none;
    }

}